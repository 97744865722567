import { createBrowserRouter } from "react-router-dom";
import AccountSetup from "../components/account-setup/AccountSetup";
import AppContent from "../components/AppContent";
import UnderConstruction from "../components/under-construction/UnderConstruction";
import ReviewDetails from "../components/account-setup/ReviewDetails";
import BusinessDetails from "../components/account-setup/BusinessDetailsForm";
import EmailVerification from "../components/account-setup/EmailVerificationForm";
import VerificationCode from "../components/account-setup/VerificationCodeForm";
import AuthenticatedRoute from "./AuthenticatedRoutes";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppContent />,
  },
  {
    path: "/verify-saa",
    element: (
      <AuthenticatedRoute>
        <AccountSetup />
      </AuthenticatedRoute>
    ),
  },
  {
    path: "/verify-business-details",
    element: (
      <AuthenticatedRoute>
        <BusinessDetails />
      </AuthenticatedRoute>
    ),
  },
  {
    path: "/verify-email",
    element: (
      <AuthenticatedRoute>
        <EmailVerification />
      </AuthenticatedRoute>
    ),
  },
  {
    path: "/verification-code",
    element: (
      <AuthenticatedRoute>
        <VerificationCode />
      </AuthenticatedRoute>
    ),
  },
  {
    path: "review-details",
    element: (
      <AuthenticatedRoute>
        <ReviewDetails />
      </AuthenticatedRoute>
    ),
  },
  {
    path: "/thank-you",
    element: (
      <AuthenticatedRoute>
        <UnderConstruction />
      </AuthenticatedRoute>
    ),
  },
]);

export default router;
import { Variables } from "graphql-request";

export const requestMiddleware = async (
  request: RequestInit & {
    url: string;
    operationName?: string | undefined;
    variables?: Variables | undefined;
  },
  getAccessTokenSilently: {
    (): Promise<string>;
  }
) => {
  let token;
  try {
    token = await getAccessTokenSilently();
  } catch (error) {
    throw new Error("Unable to get access token");
  }
  const headers = {
    ...request.headers,
    Authorization: `Bearer ${token}`,
  };
  return {
    ...request,
    headers,
  };
};

export const cleanPercentage = (percentage: number) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  switch (true) {
    case tooLow:
      return 0;
    case tooHigh:
      return 100;
    default:
      return +percentage;
  }
};

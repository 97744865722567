import { Stack } from "@spark-web/stack";
import { useAuth } from "../../hooks/useAuth";
import { Heading } from "@spark-web/heading";
import { Button } from "@spark-web/button";
import { Text } from "@spark-web/text";
import { ChevronRightIcon } from "@spark-web/icon";
import { css } from "@emotion/css";

const Landing = () => {
  const { loginWithRedirect } = useAuth();
  const handleSignIn = () => {
    loginWithRedirect();
  };
  return (
    <Stack gap="large">
      <img
        src="/assets/get-started.png"
        alt="Get Started"
        className={css({
          alignSelf: "center",
          [`@media (min-width: 768px)`]: {
            width: "372px",
            height: "100%",
          },
        })}
      />
      <Stack padding="large" gap="large">
        <Heading level="1">
          Get paid for the job as soon as it’s done
        </Heading>
        <Text size="large">
          Create and send invoices to your clients. Get paid faster with
          Brighte.
        </Text>
      </Stack>
      <Stack padding="large">
        <Button onClick={handleSignIn}>
          Get Started <ChevronRightIcon />
        </Button>
      </Stack>
    </Stack>
  );
};

export default Landing;

import { Circle } from './Circle';
import { Content } from './Content';
import { cleanPercentage } from './helpers';

type ProgressRingProps = {
  readonly percentage: number;
  readonly label: string;
  readonly diameter?: number;
};

export function ProgressRing({ percentage, label, diameter = 128 }: ProgressRingProps) {
  const pct = cleanPercentage(percentage);
  const radius = diameter / 2;

  return (
    <svg width={diameter} height={diameter}>  
      <g transform={`rotate(-90 ${radius} ${radius})`}>
        <Circle color="#f1f4fb" radius={radius} />
        <Circle color="#00c28d" pct={pct} radius={radius} />
      </g>
      <Content value={`${pct}%`} label={label} /> 
    </svg>
  );
}

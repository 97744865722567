import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuth } from "../../hooks/useAuth";
import {
  setVerifiedUserDetails,
  resetAustralianBusinessNumber,
  VerifiedUserDetails,
} from "../../store/VerifiedUserDetailsSlice";
import {
  businessDetailsSchema,
  BusinessDetailsFormSchema,
  ABN_NUMBER_LENGTH,
} from "../../schema/businessSchema";
import ButtonWrapper from "../layout/ButtonWrapper";
import { Alert } from "@spark-web/alert";
import { Button } from "@spark-web/button";
import { ChevronRightIcon } from "@spark-web/icon";
import { Field } from "@spark-web/field";
import { InputAdornment, TextInput } from "@spark-web/text-input";
import { useNavigate } from "react-router-dom";
import { Heading } from "@spark-web/heading";
import { Spinner } from "@spark-web/spinner";
import { Stack } from "@spark-web/stack";
import { Text } from "@spark-web/text";
import { Inline } from "@spark-web/inline";
import { useQuery } from "@tanstack/react-query";
import { abnLookup } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";

export default function BusinessDetailsForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formMethods = useForm<BusinessDetailsFormSchema>({
    resolver: zodResolver(businessDetailsSchema),
    mode: "all",
  });

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = formMethods;

  const verifiedUserDetails = useSelector(
    (state: { verifiedUserDetails: VerifiedUserDetails }) =>
      state.verifiedUserDetails
  );

  let australianBusinessNumber = watch("australianBusinessNumber");
  if (australianBusinessNumber) {
    australianBusinessNumber = australianBusinessNumber.replaceAll(" ", "");
  }

  const { getAccessTokenSilently } = useAuth();
  const {
    data: abnData,
    error: abnError,
    isLoading: abnIsLoading,
  } = useQuery({
    queryKey: ["abnLookup", australianBusinessNumber],
    queryFn: async () => {
      return await abnLookup(australianBusinessNumber, getAccessTokenSilently);
    },
    enabled:
      !!australianBusinessNumber &&
      australianBusinessNumber.length === ABN_NUMBER_LENGTH &&
      !errors.australianBusinessNumber,
    retry: 1,
  });

  const australianBusinessData =
    abnData && Object.keys(abnData).length > 0 ? abnData : verifiedUserDetails;

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      register("australianBusinessNumber").onChange(event);
      dispatch(
        resetAustralianBusinessNumber({
          australianBusinessNumber: event.target.value,
        })
      );
    },
    [dispatch, register]
  );

  const onSubmit = () => {
    if (Object.keys(errors).length === 0) {
      dispatch(
        setVerifiedUserDetails({
          australianBusinessNumber:
            verifiedUserDetails.australianBusinessNumber ||
            australianBusinessNumber,
          entityName:
            verifiedUserDetails.entityName || australianBusinessData.entityName,
        })
      );
      navigate("/verify-email");
    }
  };

  return (
    <Stack
      height="full"
      width="full"
      gap="large"
      as="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Heading level="2">Add your Australian Business Number</Heading>
      <Text size="small">
        Add your ABN so we can verify your business details.
      </Text>
      <Stack marginTop="xlarge" gap="large">
        <Field
          label="ABN"
          tone={errors ? "critical" : "neutral"}
          message={errors.australianBusinessNumber?.message}
        >
          <TextInput
            type="text"
            {...register("australianBusinessNumber")}
            value={verifiedUserDetails.australianBusinessNumber || ""}
            onChange={handleInputChange}
          >
            {abnIsLoading ? (
              <InputAdornment placement="end">
                <Spinner tone="primary" />
              </InputAdornment>
            ) : null}
          </TextInput>
        </Field>
        {abnError && <Alert tone="critical">{abnError.message}</Alert>}
      </Stack>
      {australianBusinessData?.entityName && (
        <Inline gap="small" marginTop="large">
          <Text size="standard">
            Is your business name {australianBusinessData.entityName} ?
          </Text>
        </Inline>
      )}
      <ButtonWrapper>
        <Button type="submit" disabled={!australianBusinessData.entityName}>
          Yes, continue <ChevronRightIcon />
        </Button>
      </ButtonWrapper>
    </Stack>
  );
}

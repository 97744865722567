type ContentProps = {
  readonly value: string;
  readonly label: string;
};
export function Content({ value, label }: ContentProps) {
  const style = {
    x: '50%',
    y: '50%',
    dominantBaseline: 'central',
    textAnchor: 'middle',
    fontSize: '1.5em',
    fontWeight: 600,
    fontFamily: `"Aestetico", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
  };

  return (
    <>
      <text {...style} dy={-7}>
        {value}
      </text>
      <text {...style} fontSize=".8em" dy={14}>
        {label}
      </text>
    </>
  );
}

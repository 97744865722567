import { ReactNode, useEffect, useMemo, useState } from "react";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { useAuth0 } from "@auth0/auth0-react";
import { AnalyticsContext, AnalyticsContextType } from '../utils/analyticsContext';

AnalyticsContext.displayName = "AnalyticsContext";
export default function AnalyticsProvider({ children }: { children: ReactNode }) {
  const { user } = useAuth0();

  const [identified, setIdentified] = useState<boolean>(false);

  const segmentKey =
    import.meta.env.VITE_ENV !== "prod"
      ? "SkrbRCv0fPAonV8wBx2QlGCjtKDIfITc"
      : "pesN8U06OgpG8QFGUnjdv5ozy0irubtO";
  const analytics = useMemo(
    () => AnalyticsBrowser.load({ writeKey: segmentKey }),
    [segmentKey]
  );
  const analyticsContext: AnalyticsContextType = useMemo(
    () => ({ track: async () => null, page: async () => null, reset: async () => null }),
    []
  );

  useEffect(() => {
    async function identifyUser() {
      if (analytics) {
        const defaultPayload = (payload?: Record<string, unknown>) => ({
          ...payload,
          sub: user?.sub,
        });
        analyticsContext.track = async (
          eventName: string,
          payload?: Record<string, unknown>
        ) => {
          await analytics.track(eventName, defaultPayload(payload));
        };
        analyticsContext.page = async (
          eventName: string,
          payload?: Record<string, unknown>
        ) => {
          await analytics.page(eventName, defaultPayload(payload));
        };
        analyticsContext.reset = async () => {
          await analytics.reset();
        };
        if (user?.sub && !identified) {
          await analytics.identify(user?.sub, {
            sub: user?.sub,
            name: user?.name,
          });
          setIdentified(true);
        }
      }
    }
    identifyUser();
  }, [user, analytics, analyticsContext, identified]);

  return (
    <AnalyticsContext.Provider value={analyticsContext}>
      {children}
    </AnalyticsContext.Provider>
  );
}

import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

datadogRum.init({
  applicationId: import.meta.env.VITE_DATADOG_RUM_APPLICATION_ID,
  clientToken: import.meta.env.VITE_DATADOG_RUM_CLIENT_TOKEN,
  site: "datadoghq.com",
  service: "installer_app",
  env: import.meta.env.VITE_ENV,
  version: import.meta.env.VITE_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  allowedTracingUrls: [
    import.meta.env.VITE_API_URL,
    import.meta.env.VITE_GRAPHQL_URL,
  ],
  enableExperimentalFeatures: ["feature_flags"],
});

datadogRum.startSessionReplayRecording();

export default function DatadogRum() {
  const { user } = useAuth0();
  useEffect(() => {
    if (user?.sub) {
      datadogRum.setUser({
        sub: user.sub,
        email: user.email ?? "",
        name: user.name ?? "",
      });
    }
  }, [user?.sub, user?.email, user?.name]);

  return null;
}

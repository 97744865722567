import { MatchedAbnSaaInfo } from "../types";

enum Accreditations {
  GridConnect = "Grid Connect",
  StandalonePowerSystems = "Standalone Power Systems",
  GridConnectBatterySystems = "Grid Connect Battery Systems",
}

enum AccreditationTypes {
  "Design & Install" = "Design & Install",
  "Design Only" = "Design Only",
  "Install Only" = "Install Only",
}
interface AccreditationInfo {
  design: string;
  install: string;
}
interface AccreditationsInfo {
  [Accreditations.GridConnect]: AccreditationInfo;
  [Accreditations.StandalonePowerSystems]: AccreditationInfo;
}

type FormattedAccreditations = {
  [K in Accreditations]?: AccreditationTypes;
};

export function getAccreditations(
  matchedAbnSaaInfoData: MatchedAbnSaaInfo
): AccreditationsInfo {
  return {
    [Accreditations.GridConnect]: {
      design: matchedAbnSaaInfoData.gridConnectDesign,
      install: matchedAbnSaaInfoData.gridConnectInstall,
    },
    [Accreditations.StandalonePowerSystems]: {
      design: matchedAbnSaaInfoData.standalonePowerSystemsDesign,
      install: matchedAbnSaaInfoData.standalonePowerSystemsInstall,
    },
  };
}

export function formatAccreditations(
  accreditations: AccreditationsInfo
): FormattedAccreditations {
  const formattedAccreditations: FormattedAccreditations = {};

  for (const [role, { design, install }] of Object.entries(accreditations) as [
    Accreditations,
    { design: string; install: string }
  ][]) {
    switch (true) {
      case design === "Y" && install === "Y":
        formattedAccreditations[role] = AccreditationTypes["Design & Install"];
        break;
      case design === "Y":
        formattedAccreditations[role] = AccreditationTypes["Design Only"];
        break;
      case install === "Y":
        formattedAccreditations[role] = AccreditationTypes["Install Only"];
        break;
    }
  }
  return formattedAccreditations;
}

export function addGridConnectBatterySystems(
  formattedAccreditations: FormattedAccreditations,
  matchedAbnSaaInfoData: MatchedAbnSaaInfo
): FormattedAccreditations {
  if (
    [
      AccreditationTypes["Design & Install"],
      AccreditationTypes["Design Only"],
      AccreditationTypes["Install Only"],
    ].includes(
      matchedAbnSaaInfoData.gridConnectBatterySystems as AccreditationTypes
    )
  ) {
    formattedAccreditations = {
      ...formattedAccreditations,
      [Accreditations.GridConnectBatterySystems]:
        AccreditationTypes[
          matchedAbnSaaInfoData.gridConnectBatterySystems as keyof typeof AccreditationTypes
        ],
    };
  }

  return formattedAccreditations;
}

export default function formatSaaRoles(
  matchedAbnSaaInfoData?: MatchedAbnSaaInfo
) {
  if (!matchedAbnSaaInfoData) {
    return;
  }
  const accreditations = getAccreditations(matchedAbnSaaInfoData);

  let formattedAccreditations = formatAccreditations(accreditations);
  formattedAccreditations = addGridConnectBatterySystems(
    formattedAccreditations,
    matchedAbnSaaInfoData
  );
  return formattedAccreditations;
}

import { GraphQLClient } from "graphql-request";
import { useAuth } from "./useAuth";
import { requestMiddleware } from "../utils/requestMiddleware";

export const useGraphQLClient = () => {
  const { getAccessTokenSilently } = useAuth();

  const gqlClient = new GraphQLClient(import.meta.env.VITE_GRAPHQL_URL, {
    requestMiddleware: (request) => requestMiddleware(request, getAccessTokenSilently),
  });

  return gqlClient;
};
import { graphql } from "@installer/graphql/gql";
import { Installer } from "@installer/graphql/graphql";

export const installersQuery = graphql(/* GraphQL */ `
  query Installers {
    installers {
      id
      firstName
      lastName
      saaNumber
      australianBusinessNumber
      tradingName
      mobileNumber
      email
      approved
      approvedBy
      userId
    }
  }
`);
export type installersQueryReturn = { installers: Installer[] };
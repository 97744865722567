import { GraphQLClient } from "graphql-request";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ProfileQuery } from "@installer/graphql/graphql";
import { profileQuery } from "@installer/frontend/queries";
import ButtonWrapper from "./layout/ButtonWrapper";
import { Spinner } from "@spark-web/spinner";
import { Button } from "@spark-web/button";
import { Stack } from "@spark-web/stack";
import { Heading } from "@spark-web/heading";
import { CheckCircleIcon, XCircleIcon } from "@spark-web/icon";
import UnderConstruction from "./under-construction/UnderConstruction";

export default function Registration({ gqlClient }: {
  gqlClient: GraphQLClient;
}) {
  const { user } = useAuth0();
  const navigate = useNavigate();

  const {
    data: profileData,
    error: profileError,
    isLoading: profileLoading,
  } = useQuery<ProfileQuery>({
    queryKey: ["installerProfile"],
    queryFn: async () => await gqlClient.request(profileQuery),
  });

  return (
    <Stack height="full" gap="large" width="full" align="center">
      {profileLoading && <Spinner size="xsmall" tone="primary" />}
      {profileData?.installerProfile && <UnderConstruction></UnderConstruction>}
      {profileData?.installerProfile === null && user?.phone_number && (
        <>
          <CheckCircleIcon size="large" tone={"primaryHover"} />
          <Heading level="2" align="center" title={user?.phone_number}>
            Mobile verified
          </Heading>
          <ButtonWrapper>
            <Button
              onClick={() => {
                navigate("/verify-saa");
              }}
            >
              Continue
            </Button> 
          </ButtonWrapper>
        </>
      )}
      {(profileError || !user?.phone_number) && (
        <>
          <XCircleIcon size="large" tone="caution" />
          <Heading level="2" align="center" title={user?.phone_number}>
            Error encountered
          </Heading>
        </>
      )}
    </Stack>
  );
}

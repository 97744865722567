import { z } from "zod";

export const verificationCodeFormSchema = z.object({
  verificationCode: z
    .string()
    .trim()
    .refine((value) => /^\d{6}$/.test(value), {
      message: "Please enter a valid 6 digit code",
    }),
});

/**
 * The type of the form schema
 */
export type VerificationCodeFormSchema = z.infer<
  typeof verificationCodeFormSchema
>;

import { useEffect, useState } from 'react';

type Timer = { max: number; interval?: number; direction?: 'asc' | 'desc' };

export function useTimer({ max, interval = 100, direction = 'desc' }: Timer) {
  const isDesc = direction === 'desc';
  const [time, setTime] = useState(isDesc ? max : 0);
  const resetTimer = () => {
    setTime(max);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (time > 0 && isDesc) {
        setTime(time - 1);
      }

      if (time < max && !isDesc) {
        setTime(time + 1);
      }
    }, interval);

    return () => {
      clearInterval(timer);
    };
  }, [time]);

  return {
    time,
    resetTimer,
  };
}

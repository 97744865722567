import { Navigate } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth';

const isAuthenticated = () => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated;
};

const AuthenticatedRoute = ({ children }: {
  children: React.ReactNode;
}) => {
  return isAuthenticated() ? children : <Navigate to="/" />;
};

export default AuthenticatedRoute
import { Box } from "@spark-web/box";
import {
  BriefcaseIcon,
  KeyIcon,
  MailIcon,
  OfficeBuildingIcon,
  PhoneIcon,
  ReceiptTaxIcon,
  UserIcon,
  createIcon,
  InformationCircleIcon,
  LocationMarkerIcon,
  AcademicCapIcon,
} from "@spark-web/icon";
import { Stack } from "@spark-web/stack";
import { Text } from "@spark-web/text";

export type InfoBoxLabel =
  | "Name"
  | "Mobile"
  | "Email"
  | "Address"
  | "Password"
  | "Business name"
  | "ABN"
  | "Role"
  | "SAA Number"
  | "SAA Role";

type InfoBoxIcon = ReturnType<typeof createIcon>;
type InfoBoxValue = { [key: string]: string } | string;

function renderValue(value: InfoBoxValue) {
  if (typeof value === "object") {
    return (
      <>
        {Object.keys(value).map((key, index) => (
          <Stack key={key} paddingTop="small" gap="small">
            <Text
              size="small"
              tone={value ? "neutral" : "muted"}
              weight="semibold"
            >
              {key}
            </Text>
            <Text size="small" tone="neutral" key={`${key}-${index}`}>
              {value[key]}
            </Text>
          </Stack>
        ))}
      </>
    );
  }
  return value;
}

export default function InfoBox({
  label,
  value,
}: Readonly<{
  label: InfoBoxLabel;
  value: InfoBoxValue;
}>) {
  const iconMap: Record<InfoBoxLabel, InfoBoxIcon> = {
    Name: UserIcon,
    Mobile: PhoneIcon,
    Email: MailIcon,
    Password: KeyIcon,
    "Business name": OfficeBuildingIcon,
    ABN: ReceiptTaxIcon,
    Role: BriefcaseIcon,
    "SAA Number": InformationCircleIcon,
    Address: LocationMarkerIcon,
    "SAA Role": AcademicCapIcon,
  };

  const Icon = iconMap[label];

  if (!value) return "-";

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="start"
      alignItems="center"
      gap="large"
      marginY="large"
    >
      <Box borderRadius="full" padding="small" background="secondaryLow">
        <Icon tone="secondary" size="xsmall" />
      </Box>
      <Stack gap="medium">
        <Text size="small" tone="muted">
          {label}
        </Text>

        {value ? (
          typeof value === "object" ? (
            renderValue(value)
          ) : (
            <Text size="small" tone="neutral" weight="semibold">
              {value}
            </Text>
          )
        ) : (
          "-"
        )}
      </Stack>
    </Box>
  );
}

import { Stack } from "@spark-web/stack";
import SolarAccreditationForm from "./SolarAccreditationForm";

/**
 * Account setup component
 * React hook wizard form
 * @returns 
 */
export default function AccountSetup() {
  return (
    <Stack height="full" width="full">
      <SolarAccreditationForm />
    </Stack>
  );
}

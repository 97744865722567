import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuth } from "../../hooks/useAuth";
import {
  setVerifiedUserDetails,
  resetSolarAccreditationNumber,
  VerifiedUserDetails,
} from "../../store/VerifiedUserDetailsSlice";
import {
  formSchema,
  FormSchema,
  SOLAR_ACCREDITATION_NUMBER_LENGTH,
} from "../../schema/solarAccreditationSchema";
import ButtonWrapper from "../layout/ButtonWrapper";
import { Alert } from "@spark-web/alert";
import { Button } from "@spark-web/button";
import { ChevronRightIcon } from "@spark-web/icon";
import { Field } from "@spark-web/field";
import { InputAdornment, TextInput } from "@spark-web/text-input";
import { useNavigate } from "react-router-dom";
import { Heading } from "@spark-web/heading";
import { Spinner } from "@spark-web/spinner";
import { Stack } from "@spark-web/stack";
import { Text } from "@spark-web/text";
import { useQuery } from "@tanstack/react-query";
import { fetchSaaLookup } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { Inline } from "@spark-web/inline";

export default function SolarAccreditationForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formMethods = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    mode: "all",
  });

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = formMethods;

  const verifiedUserDetails = useSelector(
    (state: { verifiedUserDetails: VerifiedUserDetails }) =>
      state.verifiedUserDetails
  );

  const solarAccreditationNumber = watch("solarAccreditationNumber");
  const { getAccessTokenSilently } = useAuth();

  const {
    data: saaData,
    error: saaError,
    isLoading: saaIsLoading,
  } = useQuery({
    queryKey: ["saaLookup", solarAccreditationNumber],
    queryFn: async () => {
      return await fetchSaaLookup(
        solarAccreditationNumber,
        getAccessTokenSilently
      );
    },
    enabled:
      !!solarAccreditationNumber &&
      solarAccreditationNumber.length === SOLAR_ACCREDITATION_NUMBER_LENGTH &&
      !errors.solarAccreditationNumber,
    retry: 1,
  });

  // Use verifiedUserDetails if saaData is empty,
  // This is to later display the user's name and Solar Accreditation Number
  const solarAccreditationData =
    saaData && Object.keys(saaData).length > 0 ? saaData : verifiedUserDetails;

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      register("solarAccreditationNumber").onChange(event);
      dispatch(
        resetSolarAccreditationNumber({
          solarAccreditationNumber: event.target.value,
        })
      );
    },
    [dispatch, register]
  );

  const onSubmit = () => {
    if (Object.keys(errors).length === 0) {
      dispatch(
        setVerifiedUserDetails({
          solarAccreditationNumber:
            verifiedUserDetails.solarAccreditationNumber ||
            solarAccreditationNumber,
          firstName: solarAccreditationData?.firstName,
          lastName: solarAccreditationData?.lastName,
        })
      );
      navigate("/verify-business-details");
    }
  };

  return (
    <Stack
      height="full"
      width="full"
      gap="large"
      as="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Heading level="2">Add your Solar Accreditation Australia number</Heading>
      <Text size="small">
        Add your SAA number so we can verify your accreditations.
      </Text>
      <Stack marginTop="xlarge" gap="large">
        <Field
          label="SAA number"
          tone={errors ? "critical" : "neutral"}
          message={errors.solarAccreditationNumber?.message}
        >
          <TextInput
            placeholder="e.g S1234567"
            type="text"
            {...register("solarAccreditationNumber")}
            value={verifiedUserDetails.solarAccreditationNumber || ""}
            onChange={handleInputChange}
          >
            {saaIsLoading ? (
              <InputAdornment placement="end">
                <Spinner tone="primary" />
              </InputAdornment>
            ) : null}
          </TextInput>
        </Field>
        {saaError && <Alert tone="critical">{saaError.message}</Alert>}
      </Stack>
      {(solarAccreditationData.firstName ||
        solarAccreditationData.lastName) && (
        <Inline gap="small" marginTop="large">
          <Text size="standard">
            Are you{" "}
            {`${solarAccreditationData.firstName || ""} ${
              solarAccreditationData.lastName || ""
            }`}
            ?
          </Text>
        </Inline>
      )}
      <ButtonWrapper>
        <Button type="submit" disabled={!solarAccreditationData.firstName}>
          Yes, continue <ChevronRightIcon />
        </Button>
      </ButtonWrapper>
    </Stack>
  );
}

import { z } from "zod";

/**
 * The schema for the abn form
 */
export const ABN_NUMBER_LENGTH = 11;

export const businessDetailsSchema = z.object({
  // The abn field is a string
  // It must be at least 11 characters long
  australianBusinessNumber: z
    .string()
    .trim()
    .min(ABN_NUMBER_LENGTH, { message: "Please enter a valid ABN number" })
    .transform((value) => value.replaceAll(' ', ''))
    .pipe(
      z
        .string()
        .max(ABN_NUMBER_LENGTH, {
          message: "The ABN number you've entered is too long",
        })
    ),
});

/**
 */
export type BusinessDetailsFormSchema = z.infer<typeof businessDetailsSchema>;

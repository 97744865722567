import { Box } from "@spark-web/box";
import { Container } from "@spark-web/container";
import { Stack } from "@spark-web/stack";

export default function ButtonWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Box
      width="full"
      display="flex"
      justifyContent="center"
      position="fixed"
      bottom={0}
      left={0}
    >
      <Container>
        <Stack width="full" padding="large" background="surface">
          {children}
        </Stack>
      </Container>
    </Box>
  );
}

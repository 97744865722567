import { graphql } from "@installer/graphql/gql";

export const profileQuery = graphql(/* GraphQL */ `
  query Profile {
    installerProfile {
      id
      firstName
      lastName
      saaNumber
      australianBusinessNumber
      tradingName
      mobileNumber
      email
      approved
      approvedBy
      userId
    }
  }
`);

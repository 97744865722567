import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  setVerifiedUserDetails,
  resetEmailAddress,
  VerifiedUserDetails,
} from "../../store/VerifiedUserDetailsSlice";
import {
  emailFormSchema,
  EmailFormSchema,
} from "../../schema/emailAddressSchema";
import ButtonWrapper from "../layout/ButtonWrapper";
import { Button } from "@spark-web/button";
import { ChevronRightIcon } from "@spark-web/icon";
import { Field } from "@spark-web/field";
import { InputAdornment, TextInput } from "@spark-web/text-input";
import { useNavigate } from "react-router-dom";
import { Heading } from "@spark-web/heading";
import { Stack } from "@spark-web/stack";
import { Text } from "@spark-web/text";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { sendEmailVerification } from "../../api";
import { Alert } from "@spark-web/alert";
import { Spinner } from "@spark-web/spinner";
import { useMutation } from "@tanstack/react-query";

export default function EmailVerificationForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formMethods = useForm<EmailFormSchema>({
    resolver: zodResolver(emailFormSchema),
    mode: "all",
  });

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = formMethods;

  const verifiedUserDetails = useSelector(
    (state: { verifiedUserDetails: VerifiedUserDetails }) =>
      state.verifiedUserDetails
  );

  const emailAddress = watch("emailAddress");

  const {
    error: sendVerificationError,
    isPending: verificationSending,
    mutate: sendVerification,
  } = useMutation({
    mutationKey: ["sendVerification", emailAddress],
    mutationFn: async (variables: { email: string }) => {
      return await sendEmailVerification(variables.email);
    },
  });

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      register("emailAddress").onChange(event);
      dispatch(
        resetEmailAddress({
          emailAddress: event.target.value,
        })
      );
    },
    [dispatch, register]
  );

  const onSubmit = async () => {
    if (Object.keys(errors).length === 0) {
      dispatch(
        setVerifiedUserDetails({
          emailAddress: (
            verifiedUserDetails.emailAddress || emailAddress
          ).toLowerCase(),
        })
      );
      sendVerification(
        { email: verifiedUserDetails.emailAddress },
        {
          onSuccess: () => navigate("/verification-code"),
        }
      );
    }
  };

  return (
    <Stack
      height="full"
      width="full"
      gap="large"
      as="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Heading level="2">Verify your email</Heading>
      <Text size="small">
        Please confirm your email address. We’ll send a secure code to verify
        your identity.
      </Text>
      <Stack marginTop="xlarge" gap="large">
        <Field
          label="Email address"
          tone={errors ? "critical" : "neutral"}
          message={errors.emailAddress?.message}
        >
          <TextInput
            placeholder="e.g you@gmail.com"
            type="text"
            {...register("emailAddress")}
            value={verifiedUserDetails.emailAddress || ""}
            onChange={handleInputChange}
          >
            {verificationSending ? (
              <InputAdornment placement="end">
                <Spinner tone="primary" />
              </InputAdornment>
            ) : null}
          </TextInput>
        </Field>
        {sendVerificationError && (
          <Alert tone="critical">Error sending verification code</Alert>
        )}
      </Stack>
      <ButtonWrapper>
        <Button
          type="submit"
          disabled={!!errors.emailAddress || verificationSending}
        >
          Send link <ChevronRightIcon />
        </Button>
      </ButtonWrapper>
    </Stack>
  );
}

import { createSlice } from "@reduxjs/toolkit";

export interface VerifiedUserDetails {
  emailAddress: string;
  emailAddressVerified: boolean;
  firstName: string;
  lastName: string;
  solarAccreditationNumber: string;
  australianBusinessNumber: string;
  entityName: string;
  secondaryAccessToken: string;
}

const initialState: VerifiedUserDetails = {
  emailAddress: "",
  emailAddressVerified: false,
  firstName: "",
  lastName: "",
  solarAccreditationNumber: "",
  australianBusinessNumber: "",
  entityName: "",
  secondaryAccessToken: "",
};

const userSlice = createSlice({
  name: "verifiedUserDetails",
  initialState: initialState,
  reducers: {
    setVerifiedUserDetails: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetVerifiedUserDetails: () => {
      return initialState;
    },
    resetSolarAccreditationNumber: (_state, action) => {
      return {
        ...initialState,
        solarAccreditationNumber: action.payload.solarAccreditationNumber,
      };
    },
    resetAustralianBusinessNumber: (state, action) => {
      return {
        ...state,
        australianBusinessNumber: action.payload.australianBusinessNumber,
        entityName: "",
      };
    },
    resetEmailAddress: (state, action) => {
      return {
        ...state,
        emailAddress: action.payload.emailAddress,
        emailAddressVerified: false,
        secondaryAccessToken: "",
      };
    },
  },
});

export const {
  setVerifiedUserDetails,
  resetSolarAccreditationNumber,
  resetAustralianBusinessNumber,
  resetEmailAddress,
} = userSlice.actions;

export default userSlice.reducer;

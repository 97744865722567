import { createContext } from "react";

export interface AnalyticsContextType {
  track: (eventName: string, payload?: Record<string, unknown>) => Promise<void | null>;
  page: (eventName: string, payload?: Record<string, unknown>) => Promise<void | null>;
  reset: () => Promise<void | null>;
}

export const AnalyticsContext = createContext<AnalyticsContextType>({
  track: async () => null,
  page: async () => null,
  reset: async () => null,
});

import { css } from "@emotion/css";
import { Link } from "@spark-web/link";
import { LogoutIcon } from "@spark-web/icon";
import { Box } from "@spark-web/box";
import { Stack } from "@spark-web/stack";
import { useAuth } from "../../hooks/useAuth";
import { AnalyticsContext } from "../../utils/analyticsContext";
import { useContext } from "react";

const Header = () => {
  const { logout, isAuthenticated } = useAuth();
  const { reset } = useContext(AnalyticsContext);
  return (
    <Box display="flex" justifyContent="end" width="full" height="large"
    className={css({
      height: "68px"
    })}>
      <Stack>
        {isAuthenticated && (
          <Link
            data-testid="logout"
            href="#"
            onClick={async () => {
              await reset();
              await logout({
                logoutParams: {
                  returnTo: window.location.origin,
                },
              });
            }}
          >
            <LogoutIcon></LogoutIcon>
          </Link>
        )}
      </Stack>
    </Box>
  );
};

export default Header;


import { z } from "zod";

export const emailFormSchema = z.object({
  emailAddress: z.string().email({ message: "Please enter a valid email address" }),
});

/**
 * The type of the form schema
 */
export type EmailFormSchema = z.infer<typeof emailFormSchema>;
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./VerifiedUserDetailsSlice";

export function setupStore(initialState = {}) {
  return configureStore({
    reducer: {
      verifiedUserDetails: userReducer
    },
    preloadedState: initialState
  });
}

export const store = setupStore();
export default store;
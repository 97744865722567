
import { z } from "zod";

/**
 * The schema for the solar accreditation form
 */
export const SOLAR_ACCREDITATION_NUMBER_LENGTH = 8;

export const formSchema = z.object({
  // The solarAccreditationNumber field is a string
  // It must be at least 8 characters long
  // It must match the regex /^[a-zA-Z]\d{7}$/
  solarAccreditationNumber: z
    .string()
    .min(SOLAR_ACCREDITATION_NUMBER_LENGTH, { message: "Please enter a valid SAA number" })
    .max(SOLAR_ACCREDITATION_NUMBER_LENGTH, { message: "The SAA number you've entered is too long" })
    .refine((value) => /^[a-zA-Z]\d{7}$/.test(value), {
      message: "Please enter a valid SAA number.",
    }),
});

/**
 * The type of the form schema
 */
export type FormSchema = z.infer<typeof formSchema>;
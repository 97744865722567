type CircleProps = {
  readonly color: string;
  readonly pct?: number;
  readonly radius?: number;
  readonly strokeWidth?: number;
};

export function Circle({ color, pct = 100, radius = 0, strokeWidth = 12 }: CircleProps) {
  const r = radius - strokeWidth;
  const circ = 2 * Math.PI * r;
  const strokeDashoffset = ((100 - pct) * circ) / 100;
  return (
    <circle
      r={r}
      cx={radius}
      cy={radius}
      fill="transparent"
      stroke={strokeDashoffset !== circ ? color : ''}
      strokeWidth={strokeWidth}
      strokeDasharray={circ}
      strokeDashoffset={strokeDashoffset}
      style={{ transition: 'all 0.3s ease-in-out' }}
      strokeLinecap="round"
      data-testid={`progress-ring-circle-${pct}`}
    ></circle>
  );
}

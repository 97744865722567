import { Box } from "@spark-web/box";
import { Heading } from "@spark-web/heading";
import { Stack } from "@spark-web/stack";
import { Text } from "@spark-web/text";
import { ProgressRing } from "../progress-ring";

type ProgressIndicatorProps = { readonly time: number; readonly max: number };

export function ProgressIndicator({ time, max }: ProgressIndicatorProps) {
  return (
    <Box paddingY="large" paddingX="xlarge">
      <Stack gap="xxlarge" alignSelf="center">
        <Box display="flex" alignItems="center" justifyContent="center">
          <ProgressRing
            percentage={Math.floor((time / max) * 100)}
            label="complete"
          />
        </Box>
        <Heading level="2" align="center">
          Verifying your details with Solar Accreditation Australia
        </Heading>
        <Text align="center">
          Hold tight! We’re just checking your details.
        </Text>
      </Stack>
    </Box>
  );
}

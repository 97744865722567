import { useEffect } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import {User } from "@auth0/auth0-react";

export const useLaunchDarkly = (user: User | undefined) => {
  const ldClient = useLDClient();

  useEffect(() => {
    if (user && ldClient) {
      ldClient.identify({
        key: user.sub,
        kind: "user",
        custom: {
          name: user.name,
          email: user.email,
        },
      });
    }
  }, [ldClient, user]);

  return ldClient;
};
import { AnalyticsContext } from "../utils/analyticsContext";
import { useContext, useEffect } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Spinner } from "@spark-web/spinner";
import { Stack } from "@spark-web/stack";
import { useAuth } from "../hooks/useAuth";
import { useLaunchDarkly } from "../hooks/useLaunchDarkly";
import { useGraphQLClient } from "../hooks/useGraphQLClient";
import Registration from "./Registration";
import UnderConstruction from "./under-construction/UnderConstruction";
import Landing from "./landing/Landing";

function AppContent() {
  const { underConstruction } = useFlags();
  const { user, isAuthenticated, isLoading } = useAuth();
  useLaunchDarkly(user);
  const { page } = useContext(AnalyticsContext);
  const gqlClient = useGraphQLClient();

  useEffect(() => {
    page("Dashboard");
  }, [page]);

  return (
    <Stack height="full" width="full" align="center">
      {isLoading && <Spinner tone="primary"></Spinner>}
      {!isLoading && (
        <>
          {!isAuthenticated && <Landing></Landing>}
          {isAuthenticated && (
            <>
              {underConstruction && (
                <Stack marginTop={"xxlarge"}>
                  <UnderConstruction />
                </Stack>
              )}
              {!underConstruction && (
                <Registration gqlClient={gqlClient}></Registration>
              )}
            </>
          )}
        </>
      )}
    </Stack>
  );
}

export default AppContent;

import { useQuery, useMutation } from "@tanstack/react-query";
import InfoBox, { InfoBoxLabel } from "../custom/InfoBox";
import { Stack } from "@spark-web/stack";
import { Text } from "@spark-web/text";
import { useSelector } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { matchedAbnSaaInfo, register as registerRequest } from "../../api";
import ButtonWrapper from "../layout/ButtonWrapper";
import { Button } from "@spark-web/button";
import { Alert } from "@spark-web/alert";
import { VerifiedUserDetails } from "../../store/VerifiedUserDetailsSlice";
import { useNavigate } from "react-router-dom";
import { Heading } from "@spark-web/heading";
import { useTimer } from "../../hooks/useTimer";
import { ProgressIndicator } from "../progress-indicator";
import formatSaaRoles from "../../utils/solarAccreditationFormatter";
import { useAuth0 } from "@auth0/auth0-react";
import { CheckCircleIcon } from "@spark-web/icon";
import { Inline } from "@spark-web/inline";
import { Box } from "@spark-web/box";
import { useContext, useEffect, useState } from "react";
import { AnalyticsContext } from "../../utils/analyticsContext";

function CheckCircleIconBox() {
  return (
    <Box marginY="large">
      <CheckCircleIcon size="xsmall" tone="primaryHover" />
    </Box>
  );
}

function VerifiedInfoBox({
  label,
  value,
  verified,
}: {
  label: InfoBoxLabel;
  value: string | undefined;
  verified: boolean;
}) {
  return value ? (
    <Inline alignY="bottom" gap="small">
      <InfoBox label={label} value={value} />
      {verified && <CheckCircleIconBox />}
    </Inline>
  ) : null;
}

export default function ReviewDetails() {
  const { getAccessTokenSilently } = useAuth();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { page, track } = useContext(AnalyticsContext);

  const [pageTracked, setPageTracked] = useState(false);
  const [registerTracked, setRegisterTracked] = useState(false);

  const verifiedUserDetails = useSelector(
    (state: { verifiedUserDetails: VerifiedUserDetails }) =>
      state.verifiedUserDetails
  );
  const MAX_WAIT_TIME = 30;

  const { time } = useTimer({
    max: MAX_WAIT_TIME,
    interval: 1000,
    direction: "asc",
  });

  const {
    data: matchedAbnSaaInfoData,
    error: matchedAbnSaaInfoError,
    isLoading: matchedAbnSaaInfoLoading,
  } = useQuery({
    queryKey: [
      "matchedAbnSaaInfo",
      verifiedUserDetails.solarAccreditationNumber,
    ],
    queryFn: async () => {
      return await matchedAbnSaaInfo(
        verifiedUserDetails.solarAccreditationNumber,
        verifiedUserDetails.australianBusinessNumber,
        getAccessTokenSilently
      );
    },
    retry: 1,
    enabled: time < MAX_WAIT_TIME,
  });

  useEffect(() => {
    if (!pageTracked) {
      page("Review Details");
      setPageTracked(true);
    }
  }, [pageTracked, page]);

  const {
    data: registeredData,
    error: registerError,
    isPending: registerLoading,
    mutate: register,
  } = useMutation({
    mutationKey: ["register"],
    mutationFn: async () => {
      const payload = {
        emailAddress: verifiedUserDetails.emailAddress,
        secondaryAccessToken: verifiedUserDetails.secondaryAccessToken,
        solarAccreditationNumber: verifiedUserDetails.solarAccreditationNumber,
        australianBusinessNumber: verifiedUserDetails.australianBusinessNumber,
      };
      return await registerRequest(payload, getAccessTokenSilently);
    },
  });

  if (
    registeredData &&
    verifiedUserDetails.solarAccreditationNumber &&
    Object.keys(registeredData).length > 0
  ) {
    if (!registerTracked) {
      track("Account Created");
      setRegisterTracked(true);
    }
    navigate("/thank-you");
  }

  const name = matchedAbnSaaInfoData
    ? `${matchedAbnSaaInfoData.firstName} ${matchedAbnSaaInfoData.lastName}`
    : "";

  const address = matchedAbnSaaInfoData
    ? `${matchedAbnSaaInfoData.addressState}, ${matchedAbnSaaInfoData.addressPostcode}`
    : "";

  const roles = formatSaaRoles(matchedAbnSaaInfoData);

  return (
    <Stack height="full" width="full" gap="large">
      {!verifiedUserDetails.solarAccreditationNumber && (
        <Alert tone="critical">Something went wrong!</Alert>
      )}
      {matchedAbnSaaInfoLoading &&
        verifiedUserDetails.solarAccreditationNumber && (
          <Stack height="full" width="full" gap="small" align="center">
            <ProgressIndicator time={time} max={MAX_WAIT_TIME} />
          </Stack>
        )}
      {!matchedAbnSaaInfoLoading &&
        verifiedUserDetails.solarAccreditationNumber &&
        matchedAbnSaaInfoData && (
          <>
            <Heading level="2">Hi {name}</Heading>
            <Text size="small">
              We’ve got your details. Please review your information below.
            </Text>
            <Stack>
              <InfoBox label="Name" value={name} />
              <VerifiedInfoBox
                label="Mobile"
                value={user?.phone_number}
                verified={!!user?.phone_number}
              />
              <InfoBox
                label="Email"
                value={
                  verifiedUserDetails.emailAddress
                }
              />
              <InfoBox
                label="Business name"
                value={matchedAbnSaaInfoData.entityName}
              />
              <InfoBox label="Address" value={address} />
              <InfoBox
                label="SAA Number"
                value={matchedAbnSaaInfoData.accreditationNumber}
              />
              {roles && <InfoBox label="SAA Role" value={roles} />}
            </Stack>
            {matchedAbnSaaInfoError && (
              <Alert tone="critical">{matchedAbnSaaInfoError.message}</Alert>
            )}
            {registerError && (
              <Alert tone="critical">{registerError.message}</Alert>
            )}
            <ButtonWrapper>
              <Button loading={registerLoading} onClick={() => register()}>
                Continue
              </Button>
            </ButtonWrapper>
          </>
        )}
    </Stack>
  );
}

import { GetTokenSilentlyOptions } from "@auth0/auth0-react";
import {
  RegisterPayload,
  SolarAccreditationAustralia,
  Business,
  MatchedAbnSaaInfo,
  VerifyEmailPayload,
} from "../types/index.ts";
import auth0 from "auth0-js";

type PayloadType = RegisterPayload;

const fetchApi = async (
  url: string,
  method: string,
  getAccessTokenSilently: () => Promise<string>,
  payload?: PayloadType
) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const response = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: payload ? JSON.stringify(payload) : undefined,
    });
    if (!response.ok) {
      const errorData = await response.json();
      if (
        errorData.code === "BAD_USER_INPUT" ||
        errorData.code === "INTERNAL_SERVER_ERROR"
      ) {
        throw new Error(errorData.message);
      }
    }
    return response.json();
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error(
      "Unable to perform lookup. Please contact Brighte support."
    );
  }
};

export const fetchSaaLookup = async (
  solarAccreditationNumber: string,
  getAccessTokenSilently: () => Promise<string>
): Promise<SolarAccreditationAustralia> => {
  const url = `${
    import.meta.env.VITE_API_URL
  }/saaLookup?solarAccreditationNumber=${solarAccreditationNumber}`;
  return fetchApi(url, "GET", getAccessTokenSilently);
};

export const matchedAbnSaaInfo = async (
  solarAccreditationNumber: string,
  australianBusinessNumber: string,
  getAccessTokenSilently: () => Promise<string>
): Promise<MatchedAbnSaaInfo> => {
  const params = new URLSearchParams({
    solarAccreditationNumber,
    australianBusinessNumber,
  }).toString();

  const url = `${import.meta.env.VITE_API_URL}/matchedAbnSaaInfo?${params}`;
  return fetchApi(url, "GET", getAccessTokenSilently);
};

export const abnLookup = async (
  australianBusinessNumber: string,
  getAccessTokenSilently: () => Promise<string>
): Promise<Business> => {
  const url = `${
    import.meta.env.VITE_API_URL
  }/abnLookup?australianBusinessNumber=${australianBusinessNumber}`;
  return fetchApi(url, "GET", getAccessTokenSilently);
};

export const register = async (
  payload: RegisterPayload,
  getAccessTokenSilently: () => Promise<string>
) => {
  const url = `${import.meta.env.VITE_API_URL}/register`;
  return fetchApi(url, "POST", getAccessTokenSilently, payload);
};

export const sendEmailVerification = async (
  email: string
): Promise<object | auth0.Auth0Error> => {
  const webAuth = new auth0.WebAuth({
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientID: import.meta.env.VITE_AUTH0_COMPANION_CLIENT_ID,
    redirectUri: window.location.toString(),
    responseType: "token id_token",
  });
  return new Promise((resolve, reject) => {
    webAuth.passwordlessStart(
      {
        connection: "email",
        send: "link",
        email,
      },
      function (error: auth0.Auth0Error | null, result: object) {
        if (error) return reject(new Error(error.description ?? error.original));
        resolve(result);
      }
    );
  });
};

export const verifyEmailAddress = async (
  { email, code }: VerifyEmailPayload,
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>
) => {
  const accessToken = await getAccessTokenSilently();
  const response = await fetch(`${import.meta.env.VITE_API_URL}/verify-email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ email, code }),
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error);
  }
  return response.json();
};

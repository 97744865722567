import DatadogRum from "./components/DatadogRum";
import { QueryClientProvider } from "@tanstack/react-query";
import { css } from "@emotion/css";
import { Container } from "@spark-web/container";
import { Stack } from "@spark-web/stack";
import router from "./routes";
import { RouterProvider } from "react-router-dom";
import Header from "./components/layout/Header";
import "./App.css";
import { useQueryClient } from "./hooks/useQueryClient";

function App() {
  const queryClient = useQueryClient();
  return (
    <Container>
      <Stack
        align="center"
        background="surface"
        padding="large"
        height="full"
        className={css({
          minHeight: "100vh",
        })}
      >
        <DatadogRum />
        <Header />
        {/* Main content goes here */}
        <Stack
          as="main"
          height="full"
          width="full"
          align={"center"}
          paddingBottom="large"
          marginBottom="xxlarge"
          className={css({
            position: "relative",
            // 140px is the height of the header and footer
            minHeight: "calc(100vh - 140px)",
          })}
        >
          <QueryClientProvider client={queryClient}>
            <RouterProvider
              router={router}
              fallbackElement={<p>Initial Load...</p>}
            />
          </QueryClientProvider>
        </Stack>
      </Stack>
    </Container>
  );
}

export default App;

import { Heading } from "@spark-web/heading";
import { Stack } from "@spark-web/stack";
import { Text } from "@spark-web/text";
import { css } from "@emotion/css";

export default function UnderConstruction() {
  return (
    <Stack width="full" height="full" gap="xxlarge">
      <img
        src="/assets/thankyou.png"
        alt="Thank you"
        width="100%"
        className={css({
          alignSelf: "center",
          [`@media (min-width: 768px)`]: {
            width: "375px",
            height: "375px",
          },
        })}
      />
      <Stack margin="large" gap="large">
      <Heading level="2" align="center">
        Going live soon!
      </Heading>
      <Text align="center" size="small">
        We’ re not quite ready yet, watch this space!
      </Text>
      </Stack>
    </Stack>
  );
}

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  setVerifiedUserDetails,
  VerifiedUserDetails,
} from "../../store/VerifiedUserDetailsSlice";
import {
  verificationCodeFormSchema,
  VerificationCodeFormSchema,
} from "../../schema/verificationCodeSchema";
import ButtonWrapper from "../layout/ButtonWrapper";
import { Button } from "@spark-web/button";
import { ChevronRightIcon } from "@spark-web/icon";
import { Field } from "@spark-web/field";
import { InputAdornment, TextInput } from "@spark-web/text-input";
import { useNavigate } from "react-router-dom";
import { Heading } from "@spark-web/heading";
import { Stack } from "@spark-web/stack";
import { Text } from "@spark-web/text";
import { useDispatch, useSelector } from "react-redux";
import { verifyEmailAddress } from "../../api";
import { Spinner } from "@spark-web/spinner";
import { Alert } from "@spark-web/alert";
import { useAuth } from "../../hooks/useAuth";
import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";

export default function VerificationCodeForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formMethods = useForm<VerificationCodeFormSchema>({
    resolver: zodResolver(verificationCodeFormSchema),
    mode: "all",
  });

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = formMethods;

  const { getAccessTokenSilently } = useAuth();

  const verifiedUserDetails = useSelector(
    (state: { verifiedUserDetails: VerifiedUserDetails }) =>
      state.verifiedUserDetails
  );

  const verificationCode = watch("verificationCode");

  const {
    error: verifyEmailError,
    isPending: verifyingEmail,
    mutate: verifyEmail,
  } = useMutation({
    mutationKey: ["verifyEmailAddress", verificationCode],
    mutationFn: async (variables: { email: string; code: string }) => {
      return await verifyEmailAddress(variables, getAccessTokenSilently);
    },
  });

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      register("verificationCode").onChange(event);
    },
    [register]
  );

  const onSubmit = async () => {
    if (Object.keys(errors).length === 0) {
      verifyEmail(
        {
          email: verifiedUserDetails.emailAddress,
          code: verificationCode,
        },
        {
          onSuccess: (data) => {
            dispatch(
              setVerifiedUserDetails({
                secondaryAccessToken: data.access_token,
              })
            );
            navigate("/review-details");
          },
        }
      );
    }
  };

  return (
    <Stack
      height="full"
      width="full"
      gap="large"
      as="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Heading level="2">Enter verification code</Heading>
      <Text size="small">
        A secure code has been sent to your email address.
      </Text>
      <Stack marginTop="xlarge" gap="large">
        <Field
          label="Verification code"
          tone={errors ? "critical" : "neutral"}
          message={errors.verificationCode?.message}
        >
          <TextInput
            placeholder="123456"
            type="text"
            {...register("verificationCode")}
            onChange={handleInputChange}
          >
            {verifyingEmail ? (
              <InputAdornment placement="end">
                <Spinner tone="primary" />
              </InputAdornment>
            ) : null}
          </TextInput>
        </Field>
        {verifyEmailError && (
          <Alert tone="critical">
            {verifyEmailError.message ??
              "Error verifying email address"}
          </Alert>
        )}
      </Stack>
      <ButtonWrapper>
        <Button
          type="submit"
          disabled={!!errors.verificationCode || verifyingEmail}
        >
          Verify email <ChevronRightIcon />
        </Button>
      </ButtonWrapper>
    </Stack>
  );
}
